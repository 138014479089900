.partner-part {
  color: #033246;
  text-decoration: none;
  margin-bottom: 16px;

  &:hover {
    color: #033246;
  }
  span {
    color: #BE9926;
    text-decoration: underline;
  }
}
.contactPointer {
  width: 38px;
  position: absolute;
  left: 0;
}
