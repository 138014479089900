.description {
  margin-top: 64px;
  width: calc(100% - 100px);
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.modal-container {
  padding: 16px;
}
.carousel-thumb {
  min-height: 240px;
  background-size: cover!important;
  background-repeat: no-repeat!important;
}
.round-client {
  width: 160px;
  height: 160px;
  border-radius: 160px;
  border: solid 3px rgba(255, 255, 255, .4);
  background-size: cover!important;
  background-repeat: no-repeat!important;
  margin-right: 6px;
}
.clients-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.clients-label {
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
  font-style: italic;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    text-align: left;
  }
}
.slick-dots {
  position: absolute;
  bottom: -40px!important;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  display: flex!important;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: none!important;

  @media screen and (max-width: 767px) {
    bottom: -45px!important;
  }
}
#map {
  height: 400px;
  width: 100%;
}

.thumb-img{
 //height: 300px!important;
  //width: 100%!important;
  background-size: cover!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.padding-30 {
  padding: 30px;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
}
.post-content--override {
  min-height: 120px;
  display: flex;
  justify-content: center;
    align-items: center;
}
