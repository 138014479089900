.left-container {
    background: rgba(0, 0, 0 , .7);
}

.hydra-title {
    padding-top: 10%;
    text-align: center;
}

.hydra-container {
    width: 100%;
}

.hydra {
    position: relative;
}

.hydra-image {
    opacity: 0.5;
}

/*
.hydra-star {
    padding: 20px;

    position: absolute;
    cursor: pointer;
}

.hydra-star.active .hydra-star-light {
   animation: none;
   background-color: cyan;
   box-shadow: 0 0 20px 5px cyan;
}
*/

.hydra-star {
    width: 1.2%;
    height: 3%;

    top: 0;
    left: 0;
    position: absolute;
    box-sizing: content-box;
}

.hydra-star-content {
    width: 100%;
    height: 100%;
    padding: 10px;

    top: -10px;
    left: -10px;
    position: relative;

    cursor: pointer;
    box-sizing: content-box;
}

.hydra-star-light {
    width: 80px;
    height: 80px;
    margin-top: -36px;
    margin-left: -36px;
    background-image: url("../hydra/star.png");
    background-repeat: no-repeat;
    border-radius: 50%;
    background-size: 80px;
    background-position: center;
    transition: all 0.5s ease-in-out;
/*   animation: fadeInFadeOut 5s ease-out;
    animation-iteration-count: infinite;
box-shadow: 0 0 20px 5px white;
    transition: all 0.5s ease-in-out;*/
}


.hydra-star.active .hydra-star-light,
.hydra-star-content:hover .hydra-star-light {
    /*animation: none;
    background-color: cyan;
    box-shadow: 0 0 20px 5px cyan;*/
    transform: scale(1.2);
    transition: all .2s ease-in-out;
 }

.home-language-switcher {
    right: 20px;
    top: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-left: 80px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-right: 20px;
}
.home-details__img img{
    margin-top: 100px;
    max-width: 450px;
}
@media (max-width: 991px) {
    .home-details__img img{
        margin-top: -80%;
        max-width: 260px;
    }
    .home-details {
        min-height: 100vh;
    }
}
.home-details {
    min-height: 60vh;
}
.hydra-star-0 {
    /*
    left: 6.3%;
    top: 19%;
    */
    z-index: 6;
    padding-left: 8.8%;
    padding-top: 10.6%;
}

.hydra-star-1 {
    /*
    left: 31.4%;
    top: 55%;
    */
    z-index: 5;
    padding-left: 33.9%;
    padding-top: 25.2%;
}

.hydra-star-2 {
    /*
    left: 48%;
    top: 59%;
    */
    z-index: 4;
    padding-left: 50.5%;
    padding-top: 27%;
}

.hydra-star-3 {
    /*
    left: 63.5%;
    top: 48.2%;
    */
    z-index: 3;
    padding-left: 63.5%;
    padding-top: 18%;
}

.hydra-star-4 {
    /*
    left: 73.3%;
    top: 23.5%;
    */
    z-index: 2;
    padding-left: 75.8%;
    padding-top: 12.5%;
}

.hydra-star-5 {
    /*
    left: 89.5%;
    top: 31%;
    */
    z-index: 1;
    padding-left: 92%;
    padding-top: 15%;
}

@keyframes fadeInFadeOut {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
