.detail-container {
  padding: 80px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  .post-thumb {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}
.icon-container {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  margin-bottom: 32px;
  cursor: pointer;
  color: #033246;

  i {
    margin-right: 6px;
  }
}
.post-content-large {
  margin-top: 40px;
}
.entry-content {
  margin-top: 40px;
}
