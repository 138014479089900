.bg {
  background-repeat: no-repeat!important;
  background-size: cover!important;
  width: 100%;
  min-height: 60vh;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 32px;
  font-size: 20px;
  color: #033246!important;
  border-radius: 10px;

  @media (max-width: 720px) {
    height: 100%;
    font-size: 14px;
  }
}
