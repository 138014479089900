.header-desktop {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  max-width: 100%;
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  &--light {
    position: absolute;
    left: 20px;
    right: 20px;
    max-width: 100%;
    display: flex;
    width: calc(100% - 40px);
    align-items: center;
    justify-content: space-between;
    z-index: 100;
  }
  @media screen and (max-width: 991px) {
    justify-content: flex-end;
  }
  &__menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: calc(100% - 100px);

    @media screen and (max-width: 991px) {
      display: none;
    }

    &__item {
      color: #FFFFFF;
      &:hover {
        margin-top: -10px;
        box-shadow: rgb(0 0 0 / 31%) 0 9px 20px -7px;
        border-radius: 30px;
        transition: all .3s ease-in-out;
      }
      transition: all .3s ease-in-out;
      a {
        color: #FFFFFF;
        text-decoration: none;
        padding-left: 8px;
        padding-right: 8px;
      }

      &--light {
        color: var(--main-primary-color);
        &:hover {
          margin-top: -10px;
          box-shadow: rgb(0 0 0 / 31%) 0px 9px 20px -7px;
          border-radius: 30px;
          transition: all .3s ease-in-out;
        }
        transition: all .3s ease-in-out;
        a {
          color: var(--main-primary-color);
          text-decoration: none;
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
  }
  &__languages {
    width: 100px;
    background-color: rgba(255, 255, 255, .07);
    border-radius: 30px;
    box-shadow: rgb(0 0 0 / 11%) 2px 2px 7px 0px;
    @media screen and (max-width: 991px) {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0px;
      right: 0;
      box-shadow: none;
    }
  }
}
