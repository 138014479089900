.carousel-picture {
  width: 100%;
  min-height: 35vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.modal__container {
  @media (max-width: 480px) {
    display: none;
  }
}
.contact__container {
  position: relative;
  margin-top: 0px;
  @media (max-width: 991px) {
    margin-top: 0;
  }
}

.icon-box > h2 {
  //background-color: #dcbd01!important;
  color: #ffffff!important;
}
li.icon-box.react-tabs__tab--selected {
  //background-color: #dcbd01!important;
  color: #ffffff!important;
}

@media (max-width: 991px){
  .header ul.icon-menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.show-mobile {
  display: none;

  @media (max-width: 991px){
    display: block;
    overflow: hidden;
    justify-content: space-between;
  }
}
