.starfield-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  padding-left: 0;
  padding-right: 0;
}
.nebula {
  width: 100vw;
  height: 100vh;
  background-position: 50% 0%;
  background-size: contain;
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
}
.starfield {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}
