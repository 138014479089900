.header__top {
  padding: 20px;
  img {
    width: 120px;
  }
}
.header__container {
  display: flex;
  justify-content: space-between;
}

.header__languages {
  display: flex;
  padding: 16px;
  gap: 8px;
  font-size: 12px;
}

.header__lang {
  cursor: pointer;

  img {
    width: 20px;
    &:hover {
      transform: scale(1.2);
      transition: all .2s ease-in-out;
    }

    @media screen and (max-width: 991px) {
      width: 16px;
    }
  }
  transition: all .2s ease-in-out;
}
