.video-part {
  width: 100%;
  //max-width: 800px;
  //max-height: 640px;
  //min-height: 300px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  video {
    display: block;
    width: 100%;
    //height: 100%;
    object-fit: cover;

    @media screen and (max-width: 991px) {
      width: 100vw;
      height: 100vh;
    }
  }
}
